import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";

const ViewDocument = ({ handleClose, ticket_doc }) => {
  const query = new URLSearchParams(window.location.search);
  const token = query.get("aakp");

  const env =
    process.env.REACT_APP_PRODUCTION.length === 4
      ? ""
      : query.get("env") || localStorage.getItem("environment") || "dev";

  return (
    <div
      className="popup p-4"
      style={{ position: "relative", zIndex: 1, height: "75%", width: "70%" }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <p className="mb-0 titlepopup">
          {ticket_doc?.split("/").at(-1).split("-").slice(1).join("-")}
        </p>
        <CloseIcon
          style={{ cursor: "pointer" }}
          onClick={() => handleClose()}
        />
      </div>
      <div className="bulkimg_img_conatiner mt-3">
        <iframe
          style={{
            position: "relative",
            zIndex: 3,
            height: "90%",
            width: "100%",
          }}
          src={`${
            process.env.REACT_APP_BASE_URL + env
          }/view-file?file=${ticket_doc}`}
          width="100%"
          height="100%"
        ></iframe>
        <div className="loderimg">
          <CircularProgress />
        </div>
      </div>
    </div>
  );
};

export default ViewDocument;
