import "./App.css";
import Home from "./Component/Home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Signingkyc from "./Component/SingleSuport/Signingkyc";
import GeneralQuery from "./Component/SingleSuport/GeneralQuery";
import Security from "./Component/SingleSuport/Security";
import { useEffect } from "react";
import $ from "jquery";
import SingleViewCard from "./Component/ReusableComponents/SingleViewCard";
import AllTicket from "./Component/SingleSuport/AllTicket";
import Footer from "./Component/Navbar/Footer";

function App() {
  return (
    <Router basename={"/"}>
      <div className="App">
        <Switch>
          <Route path="/" component={Home} exact />
          {/* <Route path="/ZY223HMDPT" component={Home} exact /> */}
          <Route path="/Signingkyc" component={Signingkyc} exact />
          <Route path="/GeneralQuery" component={GeneralQuery} exact />
          <Route path="/Security" component={Security} exact />
          <Route path="/SingleViewCard" component={SingleViewCard} exact />
          <Route path="/AllTicket" component={AllTicket} exact />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
