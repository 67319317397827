import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import "../home.css";
import { Link, useHistory } from "react-router-dom";
import TicketCard from "../ReusableComponents/TicketCard";
import Security from "../../Assets/Img/Security.png";
import ChatWithUs from "../../Assets/Img/ChatWithUs.png";
import CallNow from "../../Assets/Img/CallNow.png";
import LeadsReleted from "../../Assets/Img/LeadsReleted.svg";
import GeneralQuery from "../../Assets/Img/GeneralQuery.png";
import Signing_Up_KYC from "../../Assets/Img/Signing_Up_KYC.png";
import Footer from "../Navbar/Footer";
import axiosClientInterceptors from "../ReusableComponents/axiosClientInterceptors";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import FilterResults from "react-filter-search";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID_KEY,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET_KEY,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID_KEY,
  appId: process.env.REACT_APP_FIREBASE_APPID_KEY,
  measurementId: process.env.REACT_APP_MEASUREMENTID_KEY,
};

firebase.initializeApp(firebaseConfig);
const AllTicket = () => {
  // const locations = window.location;
  const [singleTicket, setsingleTicket] = useState([]);
  const [search, setsearch] = useState("");
  const history = useHistory();

  const getAllTickets = async () => {
    // console.log("formData", formData);

    const res = await axiosClientInterceptors.post(`/get-all-tickets`);
    setsingleTicket(res.data.result.listofTickets);
    console.log(res.data.result.listofTickets);
  };

  useEffect(() => {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ screen: "All Tickets" })
    );

    getAllTickets();
  }, []);
  useEffect(() => {
    firebase.analytics().logEvent("Support All Screen", { from: "web" });
  }, []);

  return (
    <div>
      <Navbar />
      {/* ============================web========================================= */}
      <div className="SingleViewCardweb">
        <div style={{ width: "80%", margin: "auto" }}>
          <div className="breadcrumb pt-3">
            <div class="backBtnVue" onClick={() => window.history.back()}>
              <KeyboardArrowLeftRoundedIcon />
              <p class="backBtnTxt">Back</p>
            </div>
          </div>
          {/* table */}
          <div className="p-4 " style={{ background: "#FFFFFF" }}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p>All Tickets</p>
              <div class="search">
                <input
                  type="text"
                  value={search}
                  onChange={(e) => setsearch(e.target.value)}
                  class="search__input"
                  placeholder="Search by ticket ID"
                />
                <button class="search__button">
                  <svg
                    class="search__icon"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                  >
                    <g>
                      <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                    </g>
                  </svg>
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-md-1  tableHead ">SL No</div>
              <div className="col-md-2 tableHead">Ticket ID</div>
              <div className="col-md-2 tableHead">Status</div>
              <div className="col-md-2 tableHead">Subject</div>
              <div className="col-md-3 tableHead">Raised on</div>
              <div className="col-md-2 tableHead text-center">Action</div>
            </div>
            <FilterResults
              value={search}
              data={singleTicket}
              renderResults={(Resultsal) => (
                <>
                  {Resultsal.map((val, i) => (
                    <div
                      className="row"
                      key={i}
                      onClick={() => {
                        history.push(`/SingleViewCard?id=${val._id}`);
                      }}
                    >
                      <div className="col-md-1  onetablebody ">
                        <p>{i + 1}</p>
                      </div>
                      <div className="col-md-2 onetablebody">
                        <p>{val.ticket_id}</p>
                      </div>
                      <div className="col-md-2 onetablebody">
                        {val.ticket_status === "Resolved" && (
                          <div className="ticketBTNtable">Resolved</div>
                        )}
                        {val.ticket_status === "Pending" && (
                          <div className="Openedtable">Opened</div>
                        )}
                        {val.ticket_status === "Resolving" && (
                          <div className="Resolvingtable">Resolving</div>
                        )}
                      </div>
                      <div className="col-md-2 onetablebody">
                        <p>{val.ticket_subject}</p>
                      </div>
                      <div className="col-md-3 onetablebody">
                        <p>{val.updatedAt}</p>
                      </div>
                      <div className="col-md-2 onetablebody text-center">
                        <ArrowCircleRightOutlinedIcon
                          className="view_arrow"
                          onClick={() => {
                            history.push(`/SingleViewCard?id=${val._id}`);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
            />
          </div>
        </div>
      </div>
      {/* ================================mobileview========================== */}
      <div className="mobileview">
        <div class="search">
          <input
            type="text"
            value={search}
            onChange={(e) => setsearch(e.target.value)}
            class="search__input"
            style={{ color: "#969696" }}
            placeholder="Search by ticket ID"
          />
          <button class="search__button">
            <svg class="search__icon" aria-hidden="true" viewBox="0 0 24 24">
              <g>
                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
              </g>
            </svg>
          </button>
        </div>
        <div class="parent mb-3">
          <FilterResults
            value={search}
            data={singleTicket}
            renderResults={(Resultsal) => (
              <>
                {Resultsal.map((val, i) => (
                  <div
                    class="ticketCard"
                    onClick={() => {
                      history.push(`/SingleViewCard?id=${val._id}`);
                    }}
                  >
                    {/* 1st */}

                    <TicketCard
                      img={LeadsReleted}
                      status={val.ticket_status}
                      title={val.ticket_notes}
                      Time={val.updatedAt}
                      id={val._id}
                      TicketID={val.ticket_id}
                      subtext="Add leads - Lead Status - Commission "
                    />
                  </div>
                ))}
              </>
            )}
          />
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default AllTicket;
