import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

const TicketCard = ({ title, Time, TicketID, id, status }) => {
  const history = useHistory();
  return (
    <div className="">
      {status === "Resolved" && <div className="ticketBTN">Resolved</div>}
      {status === "Pending" && <div className="Opened">Opened</div>}
      {status === "Resolving" && <div className="Resolving">Resolving</div>}
      <p className="ticketBTN_text mb-0">
        Ticket ID : {TicketID} | {Time}
      </p>
      <p
        className="ticketBTN_subtext mb-0 mt-2"
        onClick={() => history.push(`/SingleViewCard?id=${id}`)}
      >
        {title.length > 30 ? `${title.slice(0, 30)}...` : title}
      </p>
    </div>
  );
};

export default TicketCard;
