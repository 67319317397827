import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../home.css";
import Navbar from "../Navbar/Navbar";
import Document from "../../Assets/Img/Document.png";
import LogoCendrol from "../../Assets/Img/LogoCendrol.png";
import Footer from "../Navbar/Footer";

import axiosClientInterceptors from "./axiosClientInterceptors";
import Modal from "@mui/material/Modal";
import ViewDocument from "./ViewDocument";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";

const SingleViewCard = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [openmobile, setOpenmobile] = useState(false);
  const handleOpenmobile = () => setOpenmobile(true);
  const handleClosemobile = () => setOpenmobile(false);
  const [openmobile1, setOpenmobile1] = useState(false);
  const handleOpenmobile1 = () => setOpenmobile1(true);
  const handleClosemobile1 = () => setOpenmobile1(false);
  const locations = window.location;
  const [singleTicket, setsingleTicket] = useState("");
  const [ticketP, setticketP] = useState([]);

  const getAllTickets = async (idcard) => {
    // console.log("formData", formData);

    const res = await axiosClientInterceptors.get(`/get-ticket?_id=${idcard}`);
    setsingleTicket(res.data.result[0]);
    console.log(res.data.result[0]);
    const response = await axiosClientInterceptors.get(
      `/get-ticket-progress?ticket_id=${idcard}`
    );
    setticketP(response.data.result);
    console.log(response.data.result);
  };
  const Nativedata = (document) => {
    window?.ReactNativeWebView?.postMessage(JSON.stringify({ document }));
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const idcard = query.get("id");
    getAllTickets(idcard);
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ screen: "Ticket Details" })
    );
  }, []);
  return (
    <div>
      <Navbar />
      {/* =================================web ==================================== */}
      <div className="SingleViewCardweb">
        <div style={{ width: "80%", margin: "auto" }}>
          <div className="breadcrumb pt-3">
            <div class="backBtnVue" onClick={() => window.history.back()}>
              <KeyboardArrowLeftRoundedIcon />
              <p class="backBtnTxt">Back</p>
            </div>
            {/* <img className="arrow" src={Arrow} alt="..." /> */}

            {/* <p className="breadcrumb_subtxt mb-0">Ticket Details</p> */}
          </div>
          {/* content */}
          <div className=" innercontent_container">
            <div className="innercontent">
              <div>
                <p className="innercontent_subtext mb-0 ">
                  {singleTicket.ticket_subject}
                </p>
                <p className="ticketBTN_text mb-0">
                  Ticket ID : {singleTicket.ticket_id} |{" "}
                  {singleTicket.createdAt}
                </p>
              </div>
              {singleTicket.ticket_status === "Resolved" && (
                <div className="ticketBTN">Resolved</div>
              )}
              {singleTicket.ticket_status === "Pending" && (
                <div className="Opened">Opened</div>
              )}
              {singleTicket.ticket_status === "Resolving" && (
                <div className="Resolving">Resolving</div>
              )}
            </div>
            <hr />
            <p className="contentSingle">{singleTicket.ticket_notes}</p>
            {singleTicket.ticket_doc && (
              <div className="DocumentName_conteiner">
                <img src={Document} alt=".." className="me-2 docuImg" />
                <div>
                  <p className="DocumentName mb-0">
                    {singleTicket.ticket_doc
                      ?.split("/")
                      .at(-1)
                      .split("-")
                      .slice(1)
                      .join("-")}
                  </p>
                  <p
                    className="DocumentNamesubView mb-0"
                    onClick={() => {
                      handleOpen();
                      // Nativedata(singleTicket.ticket_doc);
                    }}
                  >
                    View document
                  </p>
                  <Modal
                    open={open}
                    className="popupbackground"
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <ViewDocument
                      ticket_doc={singleTicket.ticket_doc}
                      handleClose={handleClose}
                    />
                  </Modal>
                </div>
              </div>
            )}
          </div>
          {ticketP.map((val, i) => (
            <div className=" innercontent_container mt-3" key={i}>
              <div className="d-flex justify-content-between align_items-center">
                <div className="d-flex align-items-center">
                  <img
                    src={LogoCendrol}
                    alt=".."
                    className="me-2 docuImglogo"
                  />
                  <p className="DocumentName mb-0 me-1">Cendrol Support</p>
                  <p className="ticketBTN_text mb-0 mt-0">
                    |{val.progress_date_time}
                  </p>
                </div>
                {val.progress_status === "Resolved" && (
                  <div className="ticketBTN">Resolved</div>
                )}
                {val.progress_status === "Pending" && (
                  <div className="Opened">Opened</div>
                )}
                {val.progress_status === "Resolving" && (
                  <div className="Resolving">Resolving</div>
                )}
              </div>
              <div className="Resolving_content mt-3">
                <p className="Resolving_content_text">{val.progress_title}</p>
                {val.progress_document && (
                  <div className="DocumentName_conteiner">
                    <img src={Document} alt=".." className="me-2 docuImg" />
                    <div>
                      <p className="DocumentName mb-0">
                        {val.progress_document
                          ?.split("/")
                          .at(-1)
                          .split("-")
                          .slice(1)
                          .join("-")}
                      </p>
                      <p
                        className="DocumentNamesubView mb-0"
                        onClick={() => {
                          handleOpen1();
                          // Nativedata(val.progress_document);
                        }}
                      >
                        View document
                      </p>
                    </div>
                    <Modal
                      open={open1}
                      className="popupbackground"
                      onClose={handleClose1}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <ViewDocument
                        ticket_doc={val.progress_document}
                        handleClose={handleClose1}
                      />
                    </Modal>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* ===================================mobile view=============================== */}
      <div className="SingleViewCardmobile mt-2">
        <div className="SingleViewCardmobile_content">
          {singleTicket.ticket_status === "Resolved" && (
            <div className="ticketBTN">Resolved</div>
          )}
          {singleTicket.ticket_status === "Pending" && (
            <div className="Opened">Opened</div>
          )}
          {singleTicket.ticket_status === "Resolving" && (
            <div className="Resolving">Resolving</div>
          )}
          <p className="innercontent_subtext mb-0 mt-3 ">
            {singleTicket.ticket_subject}
          </p>
          <p className="ticketBTN_text mb-0 mt-2">
            Ticket ID : {singleTicket.ticket_id} | {singleTicket.createdAt}
          </p>
          <hr />
          <p className="contentSingle">{singleTicket.ticket_notes}</p>
          {singleTicket.ticket_doc && (
            <div className="DocumentName_conteiner">
              <img src={Document} alt=".." className="me-2 docuImg" />
              <div>
                <p className="DocumentName mb-0">
                  {" "}
                  {singleTicket.ticket_doc
                    ?.split("/")
                    .at(-1)
                    .split("-")
                    .slice(1)
                    .join("-")}
                </p>
                <p
                  className="DocumentNamesubView mb-0"
                  onClick={() => Nativedata(singleTicket.ticket_doc)}
                  //  onClick={handleOpen}
                >
                  View document
                </p>
              </div>
              {/* <Modal
              open={openmobile}
              className="popupbackgroundmobile"
              onClose={handleClosemobile}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <ViewDocument
                ticket_doc={singleTicket.ticket_doc}
                handleClose={handleClosemobile}
              />
            </Modal> */}
            </div>
          )}
        </div>
        {ticketP.map((val, i) => (
          <div className=" innercontent_container mt-3">
            <div className="Resolving_content mt-3">
              <div className="d-flex justify-content-between align_items-center">
                <div className="d-flex align-items-center">
                  <img
                    src={LogoCendrol}
                    alt=".."
                    className="me-2 docuImglogo"
                  />
                  <p className="DocumentName mb-0 me-1">Cendrol Support</p>
                  <p className="ticketBTN_text mb-0 mt-0">
                    | {val.progress_date_time}
                  </p>
                </div>
              </div>
              <hr />
              {val.progress_status === "Resolved" && (
                <div className="ticketBTN">Resolved</div>
              )}
              {val.progress_status === "Pending" && (
                <div className="Opened">Opened</div>
              )}
              {val.progress_status === "Resolving" && (
                <div className="Resolving">Resolving</div>
              )}
              <p className="Resolving_content_text">{val.progress_title}</p>
              {val.progress_document && (
                <div className="DocumentName_conteiner">
                  <img src={Document} alt=".." className="me-2 docuImg" />
                  <div>
                    <p className="DocumentName mb-0">
                      {" "}
                      {val.progress_document
                        ?.split("/")
                        .at(-1)
                        .split("-")
                        .slice(1)
                        .join("-")}
                    </p>
                    <p
                      className="DocumentNamesubView mb-0"
                      onClick={() => Nativedata(val.progress_document)}
                      // onClick={handleOpenmobile1}
                    >
                      View document
                    </p>
                  </div>
                  {/* <Modal
                  open={openmobile1}
                  className="popupbackgroundmobile"
                  onClose={handleClosemobile1}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <ViewDocument
                    ticket_doc={val.progress_document}
                    handleClose={handleClosemobile1}
                  />
                </Modal> */}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default SingleViewCard;
