import axios from "axios";

const query = new URLSearchParams(window.location.search);
const token = query.get("aakp");

const env =
  process.env.REACT_APP_PRODUCTION.length === 4
    ? ""
    : query.get("env") || localStorage.getItem("environment") || "dev";

const axiosClientInterceptors = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}${env}`,
  Token: localStorage.getItem("supportToken"),
});

const requestHandler = (request) => {
  if (localStorage.getItem("supportToken")) {
    request.headers["x-auth-token"] =
      localStorage.getItem("supportToken") || token;
  }

  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  const status = error.response.status;

  // window.location.href = "https://404.cendrol.com";
  if (status === 401) {
    window.location.href = "https://404.cendrol.com";
  }
  // status === 404
  //   ? window.location.reload()
  //   : status === 401 && (window.location.href = "https://404.cendrol.com");

  // localStorage.getItem("environment") !== null
  //   ? (window.location.href = "https://404.cendrol.com")
  //   : window.location.reload();

  // !localStorage.getItem("environment")
  //   ? window.location.reload()
  //   : (window.location.href = "https://404.cendrol.com");

  return Promise.reject(error);
};

axiosClientInterceptors.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

axiosClientInterceptors.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

/*
Or you can use name apiClient
export default apiClient or httpClient
*/
export default axiosClientInterceptors;
