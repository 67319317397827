import React from "react";
import { Link, NavLink } from "react-router-dom";
import Cendrol_logo from "../../Assets/Img/cendrollogomain.png";
import "../Navbar/Navbar.css";

const Navbar = () => {
  const goTo = (backLink) => {
    const query = new URLSearchParams(window.location.search);
    const env =
      process.env.REACT_APP_PRODUCTION?.length === 4
        ? "login"
        : query.get("env") || localStorage.getItem("environment") || "dev";
    window.location.href = `https://www.partner${env}.cendrol.com/${backLink}`;
  };

  return (
    <>
      <div className="container-fluid background_body" id="navbar_sm">
        <div className="row">
          <div className="Navbar d-flex justify-content-center">
            <nav
              className="navbar navbar-expand-lg navbar-light d-flex flex-row"
              style={{ width: "88%" }}
            >
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-bs-controls="navbarSupportedContent"
                aria-bs-expanded="false"
                aria-bs-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse   "
                style={{ justifyContent: "start" }}
                id="navbarSupportedContent"
              >
                {/* left */}

                <ul className="Navbar_Menu_Items  d-lg-flex  d-sm-block   p-0 m-0">
                  <img
                    src={Cendrol_logo}
                    alt="..."
                    style={{ width: "10%", marginRight: "10px" }}
                  />

                  <li className="mx-lg-3 my-sm-3  Navbar_item">
                    {" "}
                    <a
                      onClick={() => goTo("home")}
                      activeStyle={{
                        color: "#0e0e0e",
                        textDecoration: "none",
                        fontFamily: "msb",
                        cursor: "pointer",
                      }}
                      style={{
                        color: "#8c8c8c",
                        textDecoration: "none",
                        fontFamily: "mm",
                        cursor: "pointer",
                      }}
                      className="inactive"
                      // href={`https://www.partner${process.env.REACT_APP_ENV}.cendrol.com/${backLink}`}
                    >
                      Home{" "}
                    </a>
                  </li>

                  <li className="mx-lg-2 my-sm-3  Navbar_item ">
                    <a
                      onClick={() => goTo("Portfolio")}
                      activeStyle={{
                        color: "#0e0e0e",
                        textDecoration: "none",
                        fontFamily: "msb",
                        cursor: "pointer",
                      }}
                      style={{
                        color: "#8c8c8c",
                        textDecoration: "none",
                        fontFamily: "mm",
                        cursor: "pointer",
                      }}
                      className="inactive"
                      // href="https://www.partnerpreprod.cendrol.com/Portfolio"
                      // onClick={() => window.history.back()}
                    >
                      Portfolio{" "}
                    </a>
                  </li>

                  <li className="mx-lg-2 my-sm-3  Navbar_item ">
                    <a
                      onClick={() => goTo("leaderboard")}
                      activeStyle={{
                        color: "#0e0e0e",
                        textDecoration: "none",
                        fontFamily: "msb",
                        cursor: "pointer",
                      }}
                      style={{
                        color: "#8c8c8c",
                        textDecoration: "none",
                        fontFamily: "mm",
                        cursor: "pointer",
                      }}
                      className="inactive"
                      c // href="https://www.partnerpreprod.cendrol.com/CendrolCollection"
                      // onClick={() => window.history.back()}
                    >
                      Leaderboard
                    </a>
                  </li>

                  <li className="mx-lg-2 my-sm-3  Navbar_item  ">
                    {/* <NavLink
                      activeStyle={{ color: "#0e0e0e", textDecoration: "none" }}
                      style={{ color: "#8c8c8c", textDecoration: "none" }}
                      className="inactive"
                      to="/support"
                    > */}
                    <a
                      // href="https://www.partnerpreprod.cendrol.com/ZY223HMDPT"
                      activeStyle={{
                        color: "#0e0e0e",
                        textDecoration: "none",
                        fontFamily: "msb",
                        cursor: "pointer",
                      }}
                      style={{
                        color: "#0E0E0E",
                        textDecoration: "none",
                        fontFamily: "mm",
                        cursor: "pointer",
                      }}
                      className="inactive"
                    >
                      Support
                    </a>

                    {/* </NavLink> */}
                  </li>
                </ul>
              </div>
              <div
                className="collapse navbar-collapse d-flex justify-content-end"
                id="navbarSupportedContent"
              >
                {/* right */}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
