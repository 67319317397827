import React, { useEffect } from "react";
import Arrow from "../../Assets/Img/Arrow.png";
import "../home.css";
import { Link, useHistory } from "react-router-dom";
import StagePayment from "../../Assets/Img/StagePayment.svg";
import PaymentReceipt from "../../Assets/Img/PaymentReceipt.svg";
import call from "../../Assets/Img/call.png";
import logos_whatsapp_icon from "../../Assets/Img/logos_whatsapp_icon.png";
import StagePaymentWeb from "../../Assets/Img/StagePaymentWeb.svg";
import Payment_Receipt from "../../Assets/Img/Payment_Receipt.jpg";
import mobilephone from "../../Assets/Img/mobilephone.svg";
import StagePaymentWebone from "../../Assets/Img/StagePaymentWebone.svg";
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import mobilerecipt from "../../Assets/Img/mobilerecipt.png";

import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID_KEY,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET_KEY,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID_KEY,
  appId: process.env.REACT_APP_FIREBASE_APPID_KEY,
  measurementId: process.env.REACT_APP_MEASUREMENTID_KEY,
};

firebase.initializeApp(firebaseConfig);
const GeneralQuery = () => {
  const locations = window.location;
  const history = useHistory();

  useEffect(() => {
    window.scroll({
      top: 0,
    });

    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ screen: "Payment Related" })
    );
  }, []);
  useEffect(() => {
    firebase
      .analytics()
      .logEvent("Support Payment Related Screen", { from: "web" });
  }, []);
  return (
    <div>
      <Navbar />
      <div className="single_suport">
        <div className="single_suport_container">
          <div class="backBtnVue" onClick={() => history.push("/")}>
            <KeyboardArrowLeftRoundedIcon />
            <p class="backBtnTxt">Back</p>
          </div>
          {/* <div className="single_suport_title_container">
          <p className="single_suport_txt">GeneralQuery</p>
        </div> */}
          <div className="suport_bar">Payment Related</div>
          {/* mobile */}
          <div className="mobile">
            <div className="main_col">
              <div className=" col_one">
                <p className="col_one_text mb-0">
                  Post closure, the individual stage payments are detailed with
                  a corresponding receipt which upon clicking the 'View Receipt'
                  button, can be viewed. The status of each stage are also
                  clearly visible. <br /> {">"} Cendrol strives to ensure that
                  the time taken to credit your commission beats competitors and
                  surpasses the industry standard as of today. <br /> {">"}The
                  commission per sq.ft. is a slab rate dependent on the built-up
                  area of the project secured by the partner.
                </p>
                {/* <p className="col_one_subtext"> {">"} individual leads</p> */}
                <img
                  className="individualleads"
                  style={{ marginTop: "25px" }}
                  src={mobilephone}
                  alt="..."
                />
              </div>
              <div className=" col_two ">
                {/* <p className="col_two_text">
                {">"} Bulk data in the form of files (any format) that contain
                individual leads themselves.They can also take a snap of any
                physical document present & upload the same.
              </p> */}
                <img className="individualleads" src={mobilerecipt} alt="..." />
              </div>
            </div>
          </div>
          {/* web */}
          <div className="web">
            <div className="content_web">
              <p className="col_one_text web_textcenter mb-0 ">
                Post closure, the individual stage payments are detailed with a
                corresponding receipt which upon clicking the 'View Receipt'
                button, can be viewed. The status of each stage are also clearly
                visible.
                <br /> {">"} Cendrol strives to ensure that the time taken to
                credit your commission beats competitors and surpasses the
                industry standard as of today. <br />
                {">"} The commission per sq.ft. is a slab rate dependent on the
                built-up area of the project secured by the partner.
              </p>
              <img src={StagePaymentWebone} alt="..." className="web_img_two" />
              <img src={Payment_Receipt} alt="..." className="web_img_two" />
            </div>
          </div>

          {/* web */}
          <div className="suport_footer_content">
            <p className="text_help">
              Need more help? Our support team members are ready to help you
            </p>
            <div className="help">
              <button className="call_now">
                <a href="tel:9632888477" target="_blank" className="call">
                  <img className="logosvg" src={call} alt="..." />
                  <span>Call now</span>
                </a>
              </button>
              <button className="logos_whatsapp_icon">
                <a
                  href="https://wa.me/919632888477?text=I%27m%20interested%20in%20Cendrol%20Construction"
                  target="_blank"
                  className="call"
                >
                  <img
                    className="logosvg"
                    src={logos_whatsapp_icon}
                    alt="..."
                  />
                  <span className="chatwith">Chat with us</span>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default GeneralQuery;
